import React, { useState, useEffect } from "react";
import {
  BsApple,
  BsGooglePlay,
  BsPhone,
  BsLightningFill,
  BsWhatsapp,
} from "react-icons/bs";
import { IoMdFootball } from "react-icons/io";
import { PiTelevisionFill } from "react-icons/pi";
import { FaWifi, FaInstagram } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { MdVideoLibrary } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa6";
import { RiFacebookFill, RiTwitterXLine } from "react-icons/ri";
import "./home.css";
import logo from "../../../assets/logo.png";
import hc from "../../../assets/header card.png";
import man from "../../../assets/Portrait of young and happy man.webp";
import mtn from "../../../assets/mtn.png";
import airtel from "../../../assets/airtel.png";
import glo from "../../../assets/glo.png";
import dstv from "../../../assets/dstv.png";
import ikj from "../../../assets/ikj.png";
import bet from "../../../assets/bet.png";
import card from "../../../assets/card.png";
import webapp from "../../../assets/Web app.png";
import step from "../../../assets/step1.png";
// import steps from "../../../assets/step2.png";
import phone from "../../../assets/phone.png";
import bg from "../../../assets/bg image.png";
import how from "../../../assets/play text.png";
import whatsapp from '../../../assets/whatsapp.png'
import { Link } from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'
export default function Home() {
  useEffect(() => {
    AOS.init()
  })
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <>
    {/* Navigation Menu code */} 
      <nav className="bg-[#084DD6] w-full overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
          <div className="flex items-center  h-20">
            <div className="sm:hidden p-0 md:p-10 ">
              <a href="/">
                <img
                  src={logo}
                  className="w-[30%] sm:w-[40%] md:w-[30%]"
                  alt=""
                />
              </a>
            </div>
            <div className="flex justify-between w-full items-center">
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <a
                    href="#about"
                    className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium"
                  >
                    About
                  </a>
                  <a
                    href="#services"
                    className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium"
                  >
                    Services
                  </a>
                  <a
                    href="#contact"
                    className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium"
                  >
                    Contact
                  </a>
                </div>
              </div>
              <div className="logo flex-shrink-0">
                <img className="h-100 w-20" src={logo} alt="Logo" />
              </div>
              <div className="hidden md:block">
                <Link
                  to="/register"
                  className="bg-white border-white border-[1px]  text-[#084DD6] px-8 py-2 rounded-full mx-10"
                >
                  <span className="bai-jamjuree-bold text-sm">Register</span>
                </Link>
                <Link
                  to="/login"
                  className="bg-[#084DD6]  border-white border-[1px] text-white px-8 py-2 rounded-full"
                >
                  <span className="bai-jamjuree-bold text-sm">Login</span>
                </Link>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                className="bg-white inline-flex items-center justify-center p-2 rounded-md text-[#084dd6] hover:text-[#084dd6] hover:bg-[#ffff00] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#084dd6] focus:ring-white"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${menuOpen ? "hidden" : "block"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`${menuOpen ? "block" : "hidden"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            menuOpen ? "block" : "hidden"
          } md:hidden animate-slide-down`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="#about"
              className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium block"
              onClick={closeMenu}
            >
              About
            </a>
            <a
              href="#services"
              className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium block"
              onClick={closeMenu}
            >
              Services
            </a>
            <a
              href="#contact"
              className="text-gray-300 hover:bg-white hover:text-[#084DD6] px-3 py-2 rounded-full text-sm font-medium block"
              onClick={closeMenu}
            >
              Contact
            </a>
            <div className="py-3 ml-3 flex flex-row gap-2">
              <Link
                to="/register"
                className="bg-white border-white border-[1px]  text-[#084DD6] px-8 py-2 rounded-full"
              >
                <span className="bai-jamjuree-bold text-sm">Register</span>
              </Link>
              <Link
                to="/login"
                className="bg-[#084DD6]  border-white border-[1px] text-white px-8 py-2 rounded-full"
              >
                <span className="bai-jamjuree-bold text-sm">Login</span>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Landing Page Code Below */}

      <div className="hero flex items-center justify-center flex-col overflow-hidden w-full">
        <div className="hero-content mt-[5rem] max-w-app overflow-hidden w-full">
          <div className="pr-[1rem] sm:pr-[5rem] flex gap-3 flex-col">
            <h1 data-aos="fade-right" data-aos-duration="1500" className="text-[40px] sm:text-[60px] bai-jamjuree-bold">
              Paying Your <br />
              <mark>Bills</mark> Just Got Easier.
            </h1>
            <h4 data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500"> Our platform makes paying bills easy and hassle-free.</h4>
            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1500" className="flex flex-col md:flex-row gap-4 md:gap-10 ">
              <a
                href="~"
                className="bg-[#262728] text-white rounded-md flex flex-row gap-3 p-3  justify-center items-center"
              >
                {" "}
                <span>
                  <BsApple />
                </span>{" "}
                <span className="text-sm">
                  Download on the <br></br>{" "}
                  <span className="text-l">App Store</span>
                </span>
              </a>
              <a
                href="~"
                className="bg-[#262728] text-white rounded-md flex flex-row gap-5  justify-center items-center py-[1rem] sm:px-[1.8rem]"
              >
                {" "}
                <span>
                  <BsGooglePlay />
                </span>{" "}
                <span className="text-sm">
                  Get it on <br></br>{" "}
                  <span className="text-l">Google Play</span>
                </span>
              </a>
            </div>
          </div>
          <div>
            <div  className="relative overflow-hidden">
              <img data-aos="fade-left" data-aos-duration="1500" src={hc} alt="header-card" className="" />
              <img
                src={man}
                alt=""
                data-aos="zoom-in"
                data-aos-delay="200" data-aos-duration="1500"
                className="absolute top-[0rem] w-[45%] "
              />
            </div>
          </div>
        </div>
        <div data-aos="zoom-out" data-aos-duration="1500" className="flex items-center justify-center mt-20 w-[100%] overflow-hidden">
          <span className="bg-[#084DD6] text-white h-20 flex items-center justify-center  py-10 rounded-md w-[80%] max-w-app">
            {" "}
            <p className="bai-jamjuree-bold text-[12px] sm:text-[36px]">
              Over 30+ effective service providers
            </p>
          </span>
        </div>
        <div className="mt-20 w-full flex items-center justify-center">
          <div className="flex flex-row justify-around items-center w-[80%] max-w-app">
          <div className="hero-serv hover:animate-bounce cursor-pointer">
            <img src={mtn} alt="mtn" />
          </div>
          <div className="hero-serv cursor-pointer hover:animate-bounce">
            <img src={airtel} alt="airtel" />
          </div>
          <div className="hero-serv hover:animate-bounce cursor-pointer">
            <img src={glo} alt="glo" />
          </div>
          <div className="hero-serv hover:animate-bounce cursor-pointer">
            <img src={ikj} alt="ikeja distribution" />
          </div>
          <div className="hero-serv hover:animate-bounce cursor-pointer">
            <img src={dstv} alt="DSTV" />
          </div>
          <div className="hero-serv hover:animate-bounce cursor-pointer">
            <img src={bet} alt="bet" />
          </div>
          </div>

        </div>
      </div>
      <div id="services" className="services overflow-hidden">
        <h2 className="text-[32px] sm:text-[42px] bai-jamjuree-bold">
          Our Services
        </h2>
        <p className="text-[#202020] text-center mx-[1rem] sm:mx-[15rem]">
          {" "}
          Pay all your bills at once, without leaving your home with Swifttek's
          comprehensive range of services.
        </p>
        <div className="flex flex-col sm:flex-row gap-5 mt-5">
          <div data-aos="fade-up" data-aos-duration="1500" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <BsPhone />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Airtime Topup</h4>
              <p className="text-justify text-sm">
                Stay connected with your loved ones and top up your airtime with
                Swifttek. It's quick, easy, and available 24/7!
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <IoMdFootball />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Betting</h4>
              <p className="text-justify text-sm">
                Feeling lucky? Bet on your favorite sports games or events with
                Swifttek and enjoy the thrill of the game.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <PiTelevisionFill />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Cable TV Subscription</h4>
              <p className="text-justify text-sm">
                Get uninterrupted access to your favorite TV shows and channels
                by subscribing to your cable TV with Swifttek.
              </p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500"  className="flex flex-col sm:flex-row gap-5 mt-10">
          <div className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <FaWifi />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Data Bundle Topup</h4>
              <p className="text-justify text-sm">
                Stay connected to the internet no matter where you are with
                Swifttek's easy data bundle top-up service
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <BsLightningFill />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Electricity Bills</h4>
              <p className="text-justify text-sm">
                Managing household expenses can be challenging, but with
                Swifttek, paying your electricity bills is easy and stress-free.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <TbWorld />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Internet Topup</h4>
              <p className="text-justify text-sm">
                Whether you need internet for work, study, or entertainment,
                Swifttek makes it easy to top up your internet and stay
                connected
              </p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500"  className="flex flex-col sm:flex-row gap-5 mt-10">
          <div className="h-[200px] w-[350px] sm:w-[720px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <MdVideoLibrary />
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <h4 className="bai-jamjuree-bold text-left">
                Showmax subscription
              </h4>
              <p className="text-justify text-sm">
                ever miss your favorite shows again! Subscribe to Showmax with
                Swifttek and enjoy unlimited access to top-rated TV series,
                movies, and more
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" className="h-[200px] w-[350px] bg-white rounded-md flex flex-row items-center justify-center gap-5 px-10">
            <div>
              <div className="h-[50px] w-[50px] rounded-full bg-[#FFFF00] text-black flex items-center justify-center">
                <FaGraduationCap />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
              <h4 className="bai-jamjuree-bold">Education</h4>
              <p className="text-justify text-sm">
                Never miss a due date or wait in long lines again! Pay your
                education bills with ease using the Swifttek app.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-[5rem] py-10 overflow-hidden">
        <div className="process max-w-app flex flex-col sm:flex-row gap-16 px-[2rem] lg:px-[4rem] items-center justify-center ">
        <div className="flex justify-center items-center relative">
          <img data-aos="fade-right" data-aos-duration="1500"  src={card} alt="card" className="w-[200%] sm:w-[120%]" />
          <img
            src={webapp}
            alt="webapp"
            className="absolute w-[80%]"
          />
        </div>
        <div >
          <h2 className="text-[32px] sm:text-[42px] bai-jamjuree-bold">
            3 Simple Steps to Enjoy Swiftek.
          </h2>
          <div data-aos="fade-left" data-aos-duration="1500" className="flex flex-row items-center justify-center gap-5 mt-5">
            <div className="w-[50px] h-[50px] bg-white rounded-full shadow-xl flex justify-center items-center ">
              <img src={step} alt="" />
            </div>
            <div>
              <h4 className="bai-jamjuree-bold">
                Download and Install the App
              </h4>
              <p>
                Visit your app store, search for "Swiftek" and download and
                install the app on your mobile device.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500" data-aos-delay="200" className="flex flex-row items-center justify-center gap-5 mt-5">
            <div className="w-[50px] h-[50px] bg-white rounded-full shadow-xl flex justify-center items-center ">
              <img src={step} alt="" />
            </div>
            <div>
              <h4 className="bai-jamjuree-bold">
                Sign Up on Swifttek for free
              </h4>
              <p>
                Open the app and follow the quick and easy sign-up process. All
                you need is your basic personal information.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500" data-aos-delay="400" className="flex flex-row items-center justify-center  gap-5 my-5">
            <div className="w-[50px] h-[50px] bg-white rounded-full shadow-xl flex justify-center items-center ">
              <img src={step} alt="" />
            </div>
            <div>
              <h4 className="bai-jamjuree-bold">Add Funds and Pay Bills</h4>
              <p>
                Once you're signed in, you can add funds to your account and
                start paying your bills. It's that simple!
              </p>
            </div>
          </div>
          <Link
            to="/login"
            className="bg-[#084DD6]  border-white border-[1px] text-white px-8 py-2 rounded-full "
          >
            <span className="bai-jamjuree-bold text-sm">Get the app</span>
          </Link>
        </div>
        </div>

      </div>
      <div id="about" className="choose ">
        <h2 className="text-[32px] sm:text-[42px] bai-jamjuree-bold">
          Why choose Swifttek
        </h2>
        <p className="sm:mx-[18rem] mx-[.5rem] text-center">
          Esteem spirit temper too say adieus who direct esteem. It esteems
          luckily or picture placing drawing.
        </p>
        <div className="flex flex-col sm:flex-row gap-5 mt-5 items-center">
          <div data-aos="fade-up" data-aos-duration="1500"  className="h-[200px] w-[350px] sm:w-[380px] bg-white rounded-md flex flex-col justify-center  gap-5 px-10">
            <h4 className="bai-jamjuree-bold">
              Simplify Your Payments with SwiftTek
            </h4>
            <p className="text-justify text-sm">
              With Swiftek, you can enjoy a hassle-free payment experience for
              all your essential bills and services. We offer a simple, fast,
              and secure way to pay your utility bills, and even place bets all
              in one place.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" className="h-[200px] w-[350px] sm:w-[380px] bg-white rounded-md flex flex-col justify-center  gap-5 px-10">
            <h4 className="bai-jamjuree-bold">Secure and Reliable</h4>
            <p className="text-justify text-sm">
              With Swiftek, you can enjoy a hassle-free payment experience for
              all your essential bills and services. We offer a simple, fast,
              and secure way to pay your utility bills, and even place bets all
              in one place.
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-5 mt-5 items-center">
          <div data-aos="fade-up" data-aos-duration="1500" className="h-[200px] w-[350px] sm:w-[380px] bg-white rounded-md flex flex-col justify-center  gap-5 px-10">
            <h4 className="bai-jamjuree-bold">Earn Rewards</h4>
            <p className="text-justify text-sm">
              With Swiftek, you can enjoy a hassle-free payment experience for
              all your essential bills and services. We offer a simple, fast,
              and secure way to pay your utility bills, and even place bets all
              in one place.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" className="h-[200px] w-[350px] sm:w-[380px] bg-white rounded-md flex flex-col justify-center  gap-5 px-10">
            <h4 className="bai-jamjuree-bold">Save Time and Effort</h4>
            <p className="text-justify text-sm">
              With Swifttek, you can enjoy a hassle-free payment experience for
              all your essential bills and services. We offer a simple, fast,
              and secure way to pay your utility bills, and even place bets all
              in one place.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center mx-[2rem] sm:mx-[4rem] mt-10 sm:mt-0">
        <div data-aos="fade-right" data-aos-duration="1500"  className="flex flex-col">
          <h2 className="text-[32px] sm:text-[42px] bai-jamjuree-bold mb-10 sm:mb-20 pr-[.5rem] sm:pr-[6rem]">
            Try Swiftek for free on iOS and Android
          </h2>
          <div className="flex flex-col md:flex-row gap-4 md:gap-10 ">
            <a
              href="~"
              className="bg-[#262728] text-white rounded-md flex flex-row gap-3 p-3  justify-center items-center"
            >
              {" "}
              <span>
                <BsApple />
              </span>{" "}
              <span className="text-sm">
                Download on the <br></br>{" "}
                <span className="text-l">App Store</span>
              </span>
            </a>
            <a
              href="~"
              className="bg-[#262728] text-white rounded-md flex flex-row gap-5  justify-center items-center py-[1rem] sm:px-[1.8rem]"
            >
              {" "}
              <span>
                <BsGooglePlay />
              </span>{" "}
              <span className="text-sm">
                Get it on <br></br> <span className="text-l">Google Play</span>
              </span>
            </a>
          </div>
        </div>
        <div className="">
          <img
            src={phone}
            alt=""
            data-aos="zoom-in" data-aos-duration="1500"
            className="z-10 relative bottom-[-4rem] sm:bottom-[-14rem] w-[100%]"
          />
        </div>
      </div>
      <div id="contact" className="prefooter z-0 flex items-center justify-center">
        <div className="max-w-app flex items-center justify-center">
        <img
          src={bg}
          alt=""
          className="z-0 absolute right-4 w-[78%] sm:w-[38%]"
        />
        <div className="flex flex-col gap-5 justify-center items-center">
          <div className="border-solid border-[#718176] border-r-0 sm:border-r-[8px] h-20 mx-10">
            <h6>Subscribe to get updated news</h6>
          </div>
          <div>
            <img src={how} alt="" className="w-[60%] animate-spin-slow hover:animate-none cursor-pointer " />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <h2 className="text-[32px] sm:text-[42px] bai-jamjuree-bold mr-0 sm:mr-[17rem]">
            Subscribe Our Newsletter
          </h2>
          <form className="subscribe-form">
            <input
              type="email"
              placeholder="Enter your email"
              className="subscribe-input"
            />
            <button className="subscribe-btn">Subscribe</button>
          </form>
        </div>
        </div>

      </div>
      <div className="footer flex justify-center items-center">
        <div className="flex flex-row justify-between max-w-app">
          <div className="sm:flex flex-row  gap-5 items-center hidden sm:visible">
            <a href="#about">About</a>
            <a href="#services">Services </a>
            <a href="#contact">Contact</a>
          </div>
          <div className="sm:flex items-center justify-center sm:visible hidden">
            <img src={logo} alt="" className="w-[15%] " />
          </div>
          <div className="flex flex-row gap-5 items-center">
            <Link to="/login">Login </Link>
            <Link to="/register">Register </Link>
            <div className="flex flex-row gap-2 items-center justify-center">
              <a href="~">
                <RiFacebookFill />
              </a>
              <a href="~">
                <FaInstagram />
              </a>
              <a href="~">
                <RiTwitterXLine />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="">
            <span>Copyright © 2024.Swifttek</span>
          </div>
          <div className="sm:flex items-center justify-center sm:visible hidden">
            <div className="bg-white w-[30rem] h-[.115rem]"></div>
          </div>
          <div className="sm:visible ">
            <span>We make service swift</span>
          </div>
        </div>

      </div>
      <div className="fixed bottom-2 right-0 px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white">
        <a href="https://wa.me/+2348106976721?text=Hello I would love if you can assist me with ____" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center justify-center gap-2">
          {/* <img src={whatsapp} alt="" className="w-1/2"/> */}
          <span><BsWhatsapp /></span> <span>Chat with us!</span>
        </a>
      </div>
    </>
  );
}
