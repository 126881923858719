import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { FiInfo } from "react-icons/fi";
const Otp = ({ email }) => {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, i) => (i === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Combine OTP array into single string
      const otpValue = otp.join("");

      // POST request to the API endpoint with OTP
      const response = await axios.post(
        "https://swifttek.onrender.com/emailverify",
        { otp: otpValue }
      );

      // handle response here...
      setSuccess(true);
      signIn({
        token: response.data.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { email: email },
      });
    } catch (error) {
      if (!error?.response) {
        setErrorMessage("Check you Internet connection");
      } else if (error.response?.status === 400) {
        setErrorMessage("OTP Not Found");
      } else if (error.response?.status === 401) {
        setErrorMessage("Invalid OTP");
      } else {
        setErrorMessage("OTP Verification Failed");
      }
    }
  };
  useEffect(() => {
    if (success) {
      navigate("/login");
    }
  }, [success, navigate]);
  return (
    <div className="w-full h-[70vh] flex items-center justify-center flex-col">
      <h2 className="bai-jamjuree-bold text-[32px]">Enter OTP</h2>
      <p className="bai-jamjuree-light">An otp has been sent to your email for verification. <span className="text-red-500 font-md">Check Your Spam too!!! </span> </p>
      {errorMessage && (
        <span className="text-red-500 py-2 px-20 flex flex-row items-center justify-center text-[15px] gap-2 ">
          <FiInfo className="text-xl" />
          {errorMessage}
        </span>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex justify-center items-center"
      >
        <div className="flex justify-between sm:gap-6 gap-3 mt-5">
          {otp.map((data, index) => {
            return (
              <input
                className="shadow appearance-none border rounded w-10 sm:w-16 text-gray-700 py-2 px-3 text-center leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>
        <input
          type="submit"
          value="Verify OTP"
          className="p-4 gap-4 sm:w-[50%] w-[80%]  h-10 bg-[#084DD6] border border-gray-300 rounded-lg px-4 py-2 text-white  cursor-pointer absolute bottom-5"
        />
      </form>
    </div>
  );
};

export default Otp;
