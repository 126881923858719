import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import AOS from 'aos'
import 'aos/dist/aos.css'
const Login = () => {
  useEffect(() => {
    AOS.init()
  })
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const signIn = useSignIn();
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const emailRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    setIsLoading(true);
    const loginData = {
      email: form.email.value,
      password: form.password.value,
    };

    try {
      const response = await axios.post(
        "https://swifttek.onrender.com/login",
       //"https://backend.swifttek.ng/login",
        loginData
      );

      const user = response.data;
      setSuccess(true);
      
      signIn({
        auth: {
          token: user.token,
        },
        userState: {
          email: user.user.email,
        },
      });
    } catch (error) {
      if (!error?.response) {
        setErrorMessage("Check your Internet connection");
      } else if (error.response?.status === 400) {
        setErrorMessage("Email or Password not found");
      } else if (error.response?.status === 401) {
        setErrorMessage("Email or Password is incorrect");
      } else {
        setErrorMessage("Login Failed");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    }
  }, [success, navigate]);

  return (
    <>
      <div className="flex flex-col items-center  bg-[#084DD6] h-screen">
        <img src={logo} alt="" className="w-[40%] sm:w-[20%]" />

        <div data-aos="zoom-in" data-aos-duration="1500" className="w-[98%] sm:w-[40%] mx-auto rounded-md bg-white items-center justify-center flex flex-col">
          <h2 className="bai-jamjuree-bold text-[32px] text-[#084DD6]">
            Welcome
          </h2>
          <p className="bai-jamjuree-light text-[#084DD6]">
            Login to gain access
          </p>
          {errorMessage && (
            <span className="text-red-500 py-2 px-20 flex flex-row items-center justify-center text-[12px] gap-2 ">
              <FiInfo className="text-xl" />
              {errorMessage}
            </span>
          )}
          <form onSubmit={handleSubmit} className="w-[80%] pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                ref={emailRef}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute  right-0 px-4 py-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaRegEyeSlash className="h-5 w-5 text-gray-500" /> // EyeOff icon when password is visible
                ) : (
                  <FaRegEye className="h-5 w-5 text-gray-500" /> // Eye icon when password is hidden
                )}
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex-col justify-center gap-5">
              <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                  </div>

                  
                ) : (
                  "Login"
                )}
              </button>
              <Link to="/reset" className="text-red-500">Forgot Password ?</Link>
              </div>
             
              <Link
                to="/register"
                className="text-[#084DD6] bai-jamjuree-light"
              >
                Click Here to Register
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
