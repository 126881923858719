import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import hmacSHA512 from "crypto-js/hmac-sha512";
import logo from "../../../assets/logo.png";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";

const Waec = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [genId, setGenId] = useState(""); // State variable for genId
  const scriptRef = useRef(null);
  const authHeader = useAuthHeader();

  const publicKey = "jcr9orpYW6s50t3ZoDJBYgaID7gtapvI_CVASPUB";
  const privateKey = "pxkvRdJO6dnevJe6BvFZTR7I2Jd2u1Hs_CVASPRV";

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        //console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [authHeader]);

  // Load Monnify script
  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://sdk.monnify.com/plugin/monnify.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://sdk.monnify.com/plugin/monnify.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
      scriptRef.current = script;
    } else {
      setScriptLoaded(true);
    }

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, []);
  const amount = "2900";
  const handleSubmit = (event) => {
    event.preventDefault();
    setError(""); // Reset error message
    
    if (!scriptLoaded) {
      setError("Payment gateway is not loaded yet.");
      return;
    }
    const newGenId = `SW_${Math.floor(Math.random() * 1000000) + 1}`; // Generate the request ID here
    setGenId(newGenId);
    //const form = event.target;
    if (window.MonnifySDK) {
      window.MonnifySDK.initialize({
        amount: parseInt(amount, 10),
        currency: "NGN",
        reference: genId,
        customerFullName: `${userDetails.firstName} ${userDetails.lastName}`,
        customerEmail: userDetails.email,
        apiKey: "MK_TEST_XGML1DL413",
        contractCode: "5297787378",
        paymentDescription: "Swift Test",
        onComplete: (response) => {
          //console.log("Payment complete:", response);
          
          const transactionReference = response.transactionReference;
          if (response.status === "SUCCESS") {
            const payload = {
              requestId: genId,
              uniqueCode: "WAEC_21565",
              details: {
                email: email,
                phoneNumber: phone,
                amount: parseInt(amount),
              },
            };

            const accountNumber = phone;
            //console.log("Phone:", accountNumber);
            // Generate HMAC SHA-512 hash
            const paymentHash = generatePaymentHash(privateKey, payload);
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${publicKey}`,
              PaymentHash: paymentHash,
              Channel: "api",
            };

            console.log("Sending request to API...");
            axios
              .post(
                "https://billerstest.xpresspayments.com:9603/api/v1/education/waec/pin/fulfil",
                payload,
                { headers }
              )
              .then((response) => {
                const data = response.data;
                const pin = response.data.data.pin;
                const serial = response.data.data.serial;
                //console.log('Payment Response:', data);
                const product = "WAEC";

                if (data.responseCode === "00") {
                  navigate("/dashboard/waec-receipt", {
                    state: {
                      referenceId: data.referenceId,
                      amount,
                      product,
                      accountNumber,
                      name: `${userDetails.firstName} ${userDetails.lastName}`,
                      transactionReference,
                      pin,
                      serial
                    },
                  });
                } else {
                  setError(data.responseMessage);
                  //console.log("Payment failed:", data.responseMessage);
                }
              })
              .catch((error) => {
                //console.error("Error making payment:", error);
                setError(
                  "An error occurred while processing your payment. Please try again."
                );
              });
          } else {
            setError("Payment was not successful. Please try again.");
            //console.error("Payment was not successful:", response);
          }
        },
        onClose: () => {
          console.log("Payment widget closed");
        },
      });
    } else {
      setError("Payment gateway is not loaded.");
      //console.error("MonnifySDK is not loaded");
    }
  };
  const generatePaymentHash = (privateKey, payload) => {
    const message = JSON.stringify(payload);
    return hmacSHA512(message, privateKey).toString();
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 sm:ml-[30rem] ml-[5rem]  mb-6">
        <Link to="/dashboard/education" className="text-white">
          <FaArrowLeft />
        </Link>
        <div>
          <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">
          WAEC RESULT CHECKER PIN
        </h2>
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
        <form onSubmit={handleSubmit} className="w-full pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              placeholder="Enter Your Registered Waec Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              type="text"
              id="phone"
              value={phone}
              placeholder="Enter Your Registered Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amount"
            >
              Amount
            </label>
            <input
              type="number"
              id="amount"
              //value={amount}
              placeholder="2900"
              //={(e) => setAmount(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              readOnly
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Pay Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Waec;
