import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import AOS from "aos";
import waec from "../../../assets/waec.png";
import jamb from "../../../assets/jamb.png";
import neco from "../../../assets/neco.png";
import nabteb from "../../../assets/nabteb.png";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";

const Education = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="min-h-screen w-full flex flex-col items-center  bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 mb-6">
        <Link to="/dashboard" className="text-white">
          <FaArrowLeft />
        </Link>
        <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
      </div>
      <div data-aos="zoom-in" data-aos-duration="1500" className="">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">
          Pay Your Education Bill
        </h2>
        <p className="text-center text-white">
          Click on any of this educational institutions to pay your bills
        </p>
        <div className="mt-20 flex flex-row justify-around items-center gap-5">
          <Link to="/dashboard/education/waec" className="flex flex-col justify-center items-center gap-3 cursor-pointer">
            <div className="h-[80px] w-[80px] rounded-full flex justify-center items-center bg-slate-50 hover:animate-bounce cursor-pointer">
              <img src={waec} alt="mtn" />
            </div>
            <span className=" text-white">WAEC</span>
          </Link>
          <Link to="/dashboard/education/jamb" className="flex flex-col justify-center items-center gap-3 cursor-pointer">
            <div className="h-[80px] w-[80px] rounded-full flex justify-center items-center bg-slate-50 cursor-pointer hover:animate-bounce">
              <img src={jamb} alt="airtel" />
            </div>
            <span className=" text-white">JAMB</span>
          </Link>
          <Link to="/dashboard/education/nabteb" className="flex flex-col justify-center items-center gap-3 cursor-pointer">
            <div className="h-[80px] w-[80px] rounded-full flex justify-center items-center bg-slate-50 hover:animate-bounce cursor-pointer">
              <img src={nabteb} alt="glo" />
            </div>
            <span className=" text-white">NABTEB</span>
          </Link>
          <Link to="/dashboard/education/neco" className="flex flex-col justify-center items-center gap-3 cursor-pointer">
            <div className="h-[80px] w-[80px] rounded-full flex justify-center items-center bg-slate-50 hover:animate-bounce cursor-pointer">
              <img src={neco} alt="ikeja distribution" />
            </div>
            <span className=" text-white">NECO</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Education;
