import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../../assets/logo.png";
const ResetPassword = () => {
  useEffect(() => {
    AOS.init();
  });
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter OTP, Step 3: Enter new password
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingE, setIsLoadingE] = useState(false);
  const [isLoadingO, setIsLoadingO] = useState(false);
  const [isLoadingV, setIsLoadingV] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingE(true);
    try {
      const response = await axios.post("https://swifttek.onrender.com/otp", {
        email,
      });
      if (response.status === 200) {
        setStep(2);
      }
    } catch (error) {
      setErrorMessage("Failed to send OTP. Please try again.");
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingO(true);
    try {
      const otpValue = otp.join("");
      const response = await axios.post(
        "https://swifttek.onrender.com/otpverify",
        { otp: otpValue }
      );
      if (response.status === 200) {
        setStep(3);
      }
    } catch (error) {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingV(true);
    try {
      const response = await axios.post(
        "https://swifttek.onrender.com/resetpasswrd",
        { email, newPassword }
      );
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      setIsLoadingV(false);
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/login");
    }
  }, [success, navigate]);

  return (
    <div className="w-full bg-[#084DD6] h-screen flex items-center  flex-col">
      <img src={logo} alt="" className="w-[40%] sm:w-[20%]" />
      {step === 1 && (
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="w-[90%] sm:w-[40%] mx-auto mt-10 rounded-md  items-center  flex flex-col bg-white h-[50%]"
        >
          <h2 className="bai-jamjuree-bold text-[32px] text-[#084DD6]">
            Reset Password
          </h2>
          <form
            onSubmit={handleEmailSubmit}
            className="flex flex-col items-center mt-10"
          >
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <button
              className="mt-4 p-2 bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isLoadingE}
            >
              {isLoadingE ? (
                <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                </div>
              ) : (
                "Send OTP"
              )}
            </button>
          </form>
        </div>
      )}

      {step === 2 && (
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="w-[90%] sm:w-[40%] mx-auto mt-10 rounded-md  items-center  flex flex-col bg-white h-[50%]"
        >
          <h2 className="bai-jamjuree-bold text-[32px] text-[#084DD6]">
            Enter OTP
          </h2>
          {errorMessage && <span className="text-red-500">{errorMessage}</span>}
          <form
            onSubmit={handleOtpSubmit}
            className="flex flex-col items-center mt-10"
          >
            <div className="flex justify-between gap-2 mt-5">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={data}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value)) {
                      setOtp([...otp.map((d, i) => (i === index ? value : d))]);
                      if (e.target.nextSibling) {
                        e.target.nextSibling.focus();
                      }
                    }
                  }}
                  className="shadow appearance-none border rounded w-10 text-gray-700 py-2 px-3 text-center leading-tight focus:outline-none focus:shadow-outline"
                />
              ))}
            </div>
            <button
              className="mt-4 p-2 bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isLoadingO}
            >
              {isLoadingO ? (
                <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                </div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </form>
        </div>
      )}

      {step === 3 && (
        <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          className="w-[90%] sm:w-[40%] mx-auto mt-10 rounded-md  items-center  flex flex-col bg-white h-[50%]"
        >
          <h2 className="bai-jamjuree-bold text-[32px] text-[#084DD6]">
            New Password
          </h2>
          {errorMessage && <span className="text-red-500">{errorMessage}</span>}
          <form
            onSubmit={handlePasswordSubmit}
            className="flex flex-col items-center mt-10"
          >
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              className="shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <button
              className="mt-4 p-2 bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isLoadingV}
            >
              {isLoadingV ? (
                <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                </div>
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
