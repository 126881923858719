import React from "react";
import { Link, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import logo from "../../../assets/logo.png";
import { FaArrowLeft } from "react-icons/fa";
const ElectricityReciept = () => {
  const location = useLocation();
  const {
    referenceId,
    amount,
    product,
    name,
    transactionReference,
    customerAddress,
    accountNumber,
    accountType,
    unitRes,
    tokenRes,
  } = location.state;

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add multiple diagonal watermarks
    const imgProps = doc.getImageProperties(logo);
    const imgWidth = 60; // Adjust width as needed
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    const spacingX = 80; // Adjust horizontal spacing
    const spacingY = 80; // Adjust vertical spacing

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    for (let y = -imgHeight; y < pageHeight; y += spacingY) {
      for (let x = -imgWidth; x < pageWidth; x += spacingX) {
        doc.addImage(logo, "PNG", x, y, imgWidth, imgHeight, "", "FAST", 45);
      }
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    doc.setFontSize(14);
    doc.text("Transanction Receipt", 105, 40, null, null, "center");

    let startY = 50;
    const lineHeight = 10;
    const rows = [
      ["Date/Time:", `${formattedDate} ${formattedTime}`],
      ["Token:", tokenRes],
      ["Reference ID:", referenceId],
      ["Transaction Reference:", transactionReference],
      ["Amount:", amount],
      ["Biller:", product],
      ["Name:", name],
      ["Address:", customerAddress],
      ["Account Number:", accountNumber],
      ["Account Type:", accountType],
      ["Unit:", unitRes],
    ];

    const rowColors = ["#FFFFFF", "#FFFBCC"];

    rows.forEach((row, index) => {
      const y = startY + index * lineHeight;
      doc.setFillColor(rowColors[index % 2]);
      doc.rect(10, y, 190, lineHeight, "F");
      doc.setTextColor(0, 0, 0);
      doc.text(row[0], 20, y + 7);
      doc.text(row[1], 110, y + 7);
    });

    doc.save("swifttek-receipt.pdf");
  };
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 sm:ml-[30rem] ml-[5rem]  mb-6">
        <Link to="/dashboard" className="text-white">
          <FaArrowLeft />
        </Link>
        <div>
          <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
        </div>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Tansaction Receipt
        </h2>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border-b bg-yellow-100">
              <td className="py-2 font-bold">Token:</td>
              <td className="py-2">{tokenRes}</td>
            </tr>
            <tr className="border-b ">
              <td className="py-2 font-bold">Reference ID:</td>
              <td className="py-2">{referenceId}</td>
            </tr>
            <tr className="border-b bg-yellow-100">
              <td className="py-2 font-bold">Transaction Reference:</td>
              <td className="py-2">{transactionReference}</td>
            </tr>
            <tr className="border-b  ">
              <td className="py-2 font-bold">Amount:</td>
              <td className="py-2">{amount}</td>
            </tr>
            <tr className="border-b bg-yellow-100">
              <td className="py-2 font-bold">Biller:</td>
              <td className="py-2">{product}</td>
            </tr>
            <tr className="border-b  ">
              <td className="py-2 font-bold">Account Name:</td>
              <td className="py-2">{name}</td>
            </tr>
            <tr className="border-b bg-yellow-100">
              <td className="py-2 font-bold">Account Number:</td>
              <td className="py-2">{accountNumber}</td>
            </tr>
            <tr className="border-b ">
              <td className="py-2 font-bold">Account Type:</td>
              <td className="py-2">{accountType}</td>
            </tr>
            <tr className="border-b bg-yellow-100">
              <td className="py-2 font-bold">Address:</td>
              <td className="py-2">{customerAddress}</td>
            </tr>
            <tr className="border-b ">
              <td className="py-2 font-bold">Units:</td>
              <td className="py-2">{unitRes}</td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-center mt-6">
          <button
            onClick={generatePDF}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Download PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElectricityReciept;
