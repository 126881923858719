import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import hmacSHA512 from "crypto-js/hmac-sha512";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import logo from "../../../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";

const Electricity = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [amount, setAmount] = useState("");
  const [state, setState] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [validationData, setValidationData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [genId, setGenId] = useState(""); // State variable for genId
  const scriptRef = useRef(null);
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const publicKey = "jcr9orpYW6s50t3ZoDJBYgaID7gtapvI_CVASPUB";
  const privateKey = "pxkvRdJO6dnevJe6BvFZTR7I2Jd2u1Hs_CVASPRV";

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        //console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [authHeader]);

  // Load Monnify script
  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://sdk.monnify.com/plugin/monnify.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://sdk.monnify.com/plugin/monnify.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
      scriptRef.current = script;
    } else {
      setScriptLoaded(true);
    }

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, []);

  const validateAccount = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(""); // Reset error message

    const newGenId = `SW_${Math.floor(Math.random() * 1000000) + 1}`; // Generate the request ID here
    setGenId(newGenId);

    const uniqueCode = getUniqueCode(state, accountType);
    if (!uniqueCode) {
      setError("Invalid state or account type");
      return;
    }

    const payload = {
      requestId: newGenId,
      uniqueCode,
      details: {
        accountNumber,
        accountType,
      },
    };

    try {
      const response = await axios.post(
        "https://billerstest.xpresspayments.com:9603/api/v1/electricity/validate",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${publicKey}`,
          },
        }
      );

      const data = response.data;
      if (data.responseCode === "00") {
        setValidationData(data.data);
      } else {
        setError(data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      //console.error("Error validating account:", error);
      setError(
        "An error occurred while validating your account. Please try again."
      );
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!scriptLoaded) {
      setError("Payment gateway is not loaded yet.");
      return;
    }

    if (window.MonnifySDK) {
      window.MonnifySDK.initialize({
        amount: parseInt(amount, 10),
        currency: "NGN",
        reference: `SW_${Math.floor(Math.random() * 1000000000 + 1)}`,
        customerFullName: `${userDetails.firstName} ${userDetails.lastName}`,
        customerEmail: userDetails.email,
        apiKey: "MK_TEST_XGML1DL413",
        contractCode: "5297787378",
        paymentDescription: "Electricity Top-up",
        onComplete: (response) => {
          const transactionReference = response.transactionReference;
          const customerFullName = validationData.name;
          const customerAddress = validationData.address;
          if (response.status === "SUCCESS") {
            const payload = {
              requestId: genId, // Reuse the generated request ID here
              uniqueCode: getUniqueCode(state, accountType),
              details: {
                accountNumber,
                accountType,
                amount: parseInt(amount, 10),
                name: customerFullName,
                address: customerAddress,
                phoneNumber: userDetails.phoneNumber,
              },
            };

            // Generate HMAC SHA-512 hash
            const paymentHash = generatePaymentHash(privateKey, payload);
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${publicKey}`,
              PaymentHash: paymentHash,
              Channel: "api",
            };

            axios
              .post(
                "https://billerstest.xpresspayments.com:9603/api/v1/electricity/fulfil",
                payload,
                { headers }
              )
              .then((response) => {
                const data = response.data;
                const unitRes = response.data.data.unit;
                const tokenRes = response.data.data.token;
                //console.log(unitRes, tokenRes);
                if (data.responseCode === "00") {
                  navigate("/dashboard/electricity-receipt", {
                    state: {
                      referenceId: data.referenceId,
                      amount,
                      product: state,
                      name: customerFullName,
                      customerAddress,
                      transactionReference,
                      accountNumber,
                      accountType,
                      unitRes,
                      tokenRes,
                    },
                  });
                } else {
                  setError(data.responseMessage);
                }
              })
              .catch((error) => {
                //console.error("Error fulfilling payment:", error);
                setError(
                  "An error occurred while processing your payment. Please try again."
                );
              });
          } else {
            setError("Payment was not successful. Please try again.");
          }
        },
        onClose: () => {
          console.log("Payment widget closed");
        },
      });
    } else {
      setError("Payment gateway is not loaded.");
    }
  };

  const getUniqueCode = (state, accountType) => {
    const codes = {
      "YOLA ELECTRICITY": {
        prepaid: "YOLELE_68188",
        postpaid: "YOLELE_62499",
      },
      "BENIN ELECTRIC": {
        prepaid: "BENELE_01479",
        postpaid: "BENELE_57527",
      },
      "KANO ELECTRIC": {
        prepaid: "KANELE_65687",
        postpaid: "KANELE_42281",
      },
      "ENUGU ELECTRIC": {
        prepaid: "ENUELE_59185",
        postpaid: "ENUELE_36694",
      },
      "EKO ELECTRIC": {
        prepaid: "EKOELE_21004",
        postpaid: "EKOELE_89928",
      },
      "IKEJA ELECTRIC": {
        prepaid: "IKEELE_83053",
        postpaid: "IKEELE_59617",
      },
      "IBADAN ELECTRIC": {
        prepaid: "IBAELE_07927",
        postpaid: "IBAELE_31418",
      },
      "KADUNA ELECTRIC": {
        prepaid: "KADELE_10022",
        postpaid: "KADELE_78127",
      },
      "JOS ELECTRIC": {
        prepaid: "JOSELE_53091",
        postpaid: "JOSELE_00359",
      },
      "PORT HARCOURT ELECTRIC": {
        prepaid: "PORHAR_67006",
        postpaid: "PORHAR_58535",
      },
      "ABUJA ELECTRICITY": {
        prepaid: "ABUELE_56315",
        postpaid: "ABUELE_75054",
      },
    };

    return codes[state]?.[accountType] || "";
  };

  const generatePaymentHash = (privateKey, payload) => {
    const message = JSON.stringify(payload);
    return hmacSHA512(message, privateKey).toString();
  };
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 sm:ml-[30rem] ml-[5rem]  mb-6">
        <Link to="/dashboard" className="text-white">
          <FaArrowLeft />
        </Link>
        <div>
          <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex flex-col items-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">
          Electricity Top-up
        </h2>
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
        {!validationData && (
          <form onSubmit={validateAccount} className="w-full pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="state"
              >
                State Electricity
              </label>
              <select
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="">Select State</option>
                <option value="YOLA ELECTRICITY">YOLA ELECTRICITY</option>
                <option value="BENIN ELECTRIC">BENIN ELECTRIC</option>
                <option value="KANO ELECTRIC">KANO ELECTRIC</option>
                <option value="ENUGU ELECTRIC">ENUGU ELECTRIC</option>
                <option value="EKO ELECTRIC">EKO ELECTRIC</option>
                <option value="IKEJA ELECTRIC">IKEJA ELECTRIC</option>
                <option value="IBADAN ELECTRIC">IBADAN ELECTRIC</option>
                <option value="KADUNA ELECTRIC">KADUNA ELECTRIC</option>
                <option value="JOS ELECTRIC">JOS ELECTRIC</option>
                <option value="PORT HARCOURT ELECTRIC">
                  PORT HARCOURT ELECTRIC
                </option>
                <option value="ABUJA ELECTRICITY">ABUJA ELECTRICITY</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="accountType"
              >
                Account Type
              </label>
              <select
                id="accountType"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="">Select Account Type</option>
                <option value="prepaid">Prepaid</option>
                <option value="postpaid">Postpaid</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="accountNumber"
              >
                Account Number
              </label>
              <input
                type="text"
                id="accountNumber"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="flex items-center justify-between">
            <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                  </div>

                  
                ) : (
                  "Validate"
                )}
              </button>
            </div>
          </form>
        )}
        {validationData && (
          <form onSubmit={handleSubmit} className="w-full pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="amount"
              >
                Customer Name
              </label>
              <input
                type="text"
                readOnly
                placeholder={validationData.name}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="amount"
              >
                Customer Address
              </label>
              <input
                type="text"
                readOnly
                placeholder={validationData.address}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="amount"
              >
                Amount
              </label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                min="100"
                max="10000"
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Pay Now
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Electricity;
