import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import hmacSHA512 from "crypto-js/hmac-sha512";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import logo from "../../../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";

const Subscription = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planName, setPlanName] = useState("");
  const [noOfMonth, setNoOfMonth] = useState(1); // Default to 1 month
  const [accountNumber, setAccountNumber] = useState("");
  const [validationData, setValidationData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [uniqueCode, setUniqueCode] = useState("");
  const [error, setError] = useState("");
  const [genId, setGenId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const scriptRef = useRef(null);
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const publicKey = "jcr9orpYW6s50t3ZoDJBYgaID7gtapvI_CVASPUB";
  const privateKey = "pxkvRdJO6dnevJe6BvFZTR7I2Jd2u1Hs_CVASPRV";

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        //console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    // Load Monnify script
    const loadMonnifyScript = () => {
      if (
        !document.querySelector(
          'script[src="https://sdk.monnify.com/plugin/monnify.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://sdk.monnify.com/plugin/monnify.js";
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.body.appendChild(script);
        scriptRef.current = script;
      } else {
        setScriptLoaded(true);
      }

      return () => {
        if (scriptRef.current) {
          document.body.removeChild(scriptRef.current);
          scriptRef.current = null;
        }
      };
    };

    loadMonnifyScript();
  }, []);

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    setSelectedPlan("");
    setAmount(0); // Reset amount when product changes
  };

  // Function to handle plan change
  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    const [selName, amountStr, unikCode] = event.target.value.split("|");
    setPlanName(selName);
    setUniqueCode(unikCode);
    setAmount(parseInt(amountStr, 10)); // Set amount based on selected plan
  };

  const uniqueKode = uniqueCode;
  const validateAccount = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message
    setIsLoading(true);

    // Generate new request ID
    const newGenId = `SW_${Math.floor(Math.random() * 1000000) + 1}`;
    setGenId(newGenId);

    // Fetch unique code for selected plan
    //const plans = getPlansByProduct(selectedProduct);

    //const selectedPlanDetails = plans.find(plan => plan.uniqueCode === selectedPlan);

    //console.log("Unique Code:", uniqueKode);
    if (!uniqueKode) {
      setError("Invalid product or plan selection");
      return;
    }
    //const uniqueCode = selectedPlanDetails.uniqueCode;

    // Prepare payload for validation
    const payload = {
      requestId: newGenId,
      uniqueCode: uniqueKode,
      details: {
        accountNumber,
        noOfMonth,
      },
    };

    try {
      const response = await axios.post(
        "https://billerstest.xpresspayments.com:9603/api/v1/cable/validate",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${publicKey}`,
          },
        }
      );

      const data = response.data;
      if (data.responseCode === "00") {
        setValidationData(data.data);
      } else {
        setError(data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      //console.error("Error validating account:", error);
      setError(
        "An error occurred while validating your account. Please try again."
      );
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!scriptLoaded) {
      setError("Payment gateway is not loaded yet.");
      return;
    }

    if (window.MonnifySDK) {
      window.MonnifySDK.initialize({
        amount: amount,
        currency: "NGN",
        reference: `SW_${Math.floor(Math.random() * 1000000000 + 1)}`,
        customerFullName: `${userDetails.firstName} ${userDetails.lastName}`,
        customerEmail: userDetails.email,
        apiKey: "MK_TEST_XGML1DL413",
        contractCode: "5297787378",
        paymentDescription: `${selectedProduct} Subscription`,
        onComplete: (response) => {
          const transactionReference = response.transactionReference;
          // const customerFullName =
          //   userDetails.firstName + " " + userDetails.lastName;
          if (response.status === "SUCCESS") {
            const payload = {
              requestId: genId,
              uniqueCode: uniqueKode,
              details: {
                accountNumber: selectedPlan,
                noOfMonth,
                amount: amount,
                name: validationData.name,
                phoneNumber: userDetails.phoneNumber,
              },
            };

            const paymentHash = generatePaymentHash(privateKey, payload);
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${publicKey}`,
              PaymentHash: paymentHash,
              Channel: "api",
            };

            axios
              .post(
                "https://billerstest.xpresspayments.com:9603/api/v1/cable/fulfil",
                payload,
                { headers }
              )
              .then((response) => {
                const data = response.data;
                // Handle fulfillment response
                //console.log(data);
                navigate("/dashboard/subscription-receipt", {
                  state: {
                    referenceId: data.referenceId,
                    amount,
                    product: selectedProduct,
                    name: validationData.name,
                    transactionReference,
                    accountPlan: planName,
                    accountNumber,
                  },
                });
              })
              .catch((error) => {
                //console.error("Error fulfilling payment:", error);
                setError(
                  "An error occurred while processing your payment. Please try again."
                );
              });
          } else {
            setError("Payment was not successful. Please try again.");
          }
        },
        onClose: () => {
          console.log("Payment widget closed");
        },
      });
    } else {
      setError("Payment gateway is not loaded.");
    }
  };

  const getPlansByProduct = (product) => {
    switch (product) {
      case "Startimes":
        return [
          {
            id: "star1",
            uniqueCode: "STARTIMES_70367",
            name: "Startimes Combo Smart (Basic) Weekly",
            amount: 1300,
          },
          {
            id: "star2",
            uniqueCode: "STARTIMES_55996",
            name: "Startimes Combo Smart (Basic) Monthly",
            amount: 3800,
          },
          {
            id: "star3",
            uniqueCode: "STARTIMES_16025",
            name: "Startimes Combo Special (Basic) Weekly",
            amount: 1800,
          },
          {
            id: "star4",
            uniqueCode: "STARTIMES_22691",
            name: "Startimes Combo Special (Basic) Monthly",
            amount: 5600,
          },
          {
            id: "star5",
            uniqueCode: "STARTIMES_82050",
            name: "Startimes Combo Super (Classic) Weekly",
            amount: 2500,
          },
          {
            id: "star6",
            uniqueCode: "STARTIMES_03786",
            name: "Startimes Combo Super (Classic) Monthly",
            amount: 7500,
          },
          {
            id: "star17",
            uniqueCode: "STARTIMES_19468",
            name: "Startimes Classic - One Month",
            amount: 2750,
          },
          {
            id: "star18",
            uniqueCode: "STARTIMES_41244",
            name: "Startimes Smart - One Month",
            amount: 2600,
          },
          {
            id: "star19",
            uniqueCode: "STARTIMES_23874",
            name: "Startimes Basic - One Month",
            amount: 1850,
          },
          {
            id: "star20",
            uniqueCode: "STARTIMES_95960",
            name: "Startimes Super - One Week",
            amount: 1500,
          },
          {
            id: "star21",
            uniqueCode: "STARTIMES_15594",
            name: "Startimes Classic - One Week",
            amount: 1200,
          },
          {
            id: "star10",
            uniqueCode: "STARTIMES_30210",
            name: "Startimes Unique - One Week",
            amount: 1300,
          },
          {
            id: "star11",
            uniqueCode: "STARTIMES_47620",
            name: "Startimes Unique - One Month",
            amount: 3800,
          },
          {
            id: "star15",
            uniqueCode: "STARTIMES_89662",
            name: "Startimes Super - One Month",
            amount: 4900,
          },
          {
            id: "star16",
            uniqueCode: "STARTIMES_62923",
            name: "Startimes French - One Month",
            amount: 4500,
          },
          {
            id: "star14",
            uniqueCode: "STARTIMES_02904",
            name: "Startimes Chinese - One Month",
            amount: 7800,
          },
          {
            id: "star12",
            uniqueCode: "STARTIMES_27557",
            name: "Startimes Nova Solar - One Month",
            amount: 9900,
          },
          {
            id: "star13",
            uniqueCode: "STARTIMES_15855",
            name: "Startimes Super - Two Month",
            amount: 9800,
          },
          {
            id: "star7",
            uniqueCode: "STARTIMES_09386",
            name: "Startimes DTH Chinese Monthly",
            amount: 14000,
          },
          {
            id: "star8",
            uniqueCode: "STARTIMES_35670",
            name: "Startimes Smart Solar - One Month",
            amount: 10500,
          },
          {
            id: "star9",
            uniqueCode: "STARTIMES_76874",
            name: "Startimes Super Solar - One Month",
            amount: 11400,
          },
        ];
      case "GOTV":
        return [
          {
            id: "go1",
            uniqueCode: "GOTV_89056",
            name: "GOTV-LITE (1 MONTH)",
            amount: 1575,
          },
          {
            id: "go2",
            uniqueCode: "GOTV_92253",
            name: "GOTV JINJA (1 MONTH)",
            amount: 1900,
          },
          {
            id: "go3",
            uniqueCode: "GOTV_35628",
            name: "GOTV JOLLI (1 MONTH)",
            amount: 2800,
          },
          {
            id: "go4",
            uniqueCode: "GOTV_94964",
            name: "GOTV MAX FOR (1 MONTH)",
            amount: 4850,
          },
          {
            id: "go5",
            uniqueCode: "GOTV_71253",
            name: "GOTV SUPA FOR (1 MONTH)",
            amount: 6400,
          },
        ];
      case "Dstv":
        return [
          {
            id: "ds1",
            uniqueCode: "DSTV_64363",
            name: "DSTV French 11 Bouquet E36 (1 Month)",
            amount: 5150,
          },
          {
            id: "ds2",
            uniqueCode: "DSTV_59748",
            name: "DSTV PremiumAsia Showmax (1 Month)",
            amount: 27500,
          },
          {
            id: "ds3",
            uniqueCode: "DSTV_08877",
            name: "DSTV PremiumFrench + Showmax (1 Month)",
            amount: 29300,
          },
          {
            id: "ds4",
            uniqueCode: "DSTV_21020",
            name: "DSTV Premium + Showmax (1 Month)",
            amount: 24500,
          },
          {
            id: "ds5",
            uniqueCode: "DSTV_18411",
            name: "DSTV Asian + Showmax (1 Month)",
            amount: 11200,
          },
          {
            id: "ds6",
            uniqueCode: "DSTV_63114",
            name: "DSTV CompactPlus + Showmax (1 Month)",
            amount: 18050,
          },
          {
            id: "ds7",
            uniqueCode: "DSTV_86071",
            name: "DSTV Compact + Showmax (1 Month)",
            amount: 11950,
          },
          {
            id: "ds8",
            uniqueCode: "DSTV_03320",
            name: "DSTV Confam + Showmax (1 Month)",
            amount: 6750,
          },
          {
            id: "ds9",
            uniqueCode: "DSTV_43994",
            name: "DSTV Yanga + Showmax (1 Month)",
            amount: 4400,
          },
          {
            id: "ds10",
            uniqueCode: "DSTV_79170",
            name: "DSTV Padi + Showmax (1 Month)",
            amount: 5050,
          },
          {
            id: "ds11",
            uniqueCode: "DSTV_06557",
            name: "DStv Asian Add-on Bouquet E36 (1 Month)",
            amount: 7100,
          },
          {
            id: "ds12",
            uniqueCode: "DSTV_79549",
            name: "DStv French Plus Add-on Bouquet E36 (1 Month)",
            amount: 9300,
          },
          {
            id: "ds13",
            uniqueCode: "DSTV_36491",
            name: "DSTV Padi + Xtraview (1 Month)",
            amount: 5050,
          },
          {
            id: "ds14",
            uniqueCode: "DSTV_32314",
            name: "DSTV Compact + Xtraview (1 Month)",
            amount: 11900,
          },
          {
            id: "ds15",
            uniqueCode: "DSTV_80446",
            name: "DSTV Compact + French Touch + Xtraview (1 Month)",
            amount: 14550,
          },
          {
            id: "ds16",
            uniqueCode: "DSTV_00052",
            name: "DSTV Compact + Asia + Xtraview (1 Month)",
            amount: 19000,
          },
          {
            id: "ds17",
            uniqueCode: "DSTV_19778",
            name: "DSTV Yanga + Xtraview (1 Month)",
            amount: 5850,
          },
          {
            id: "ds18",
            uniqueCode: "DSTV_57505",
            name: "DSTV Compact + French Plus (1 Month)",
            amount: 18300,
          },
          {
            id: "ds19",
            uniqueCode: "DSTV_70902",
            name: "DSTV Confam + Xtraview (1 Month)",
            amount: 8200,
          },
          {
            id: "ds20",
            uniqueCode: "DSTV_22668",
            name: "DSTV Compact + French Touch (1 Month)",
            amount: 11650,
          },
          {
            id: "ds21",
            uniqueCode: "DSTV_26872",
            name: "DSTV CompactPlus + French Plus + Xtraview (1 Month)",
            amount: 26450,
          },
          {
            id: "ds22",
            uniqueCode: "DSTV_41240",
            name: "DSTV CompactPlus + Xtraview (1 Month)",
            amount: 17150,
          },
          {
            id: "ds23",
            uniqueCode: "DSTV_58295",
            name: "DSTV Compact + Asia (1 Month)",
            amount: 16100,
          },
          {
            id: "ds24",
            uniqueCode: "DSTV_70484",
            name: "DSTV PREMIUM (1 Month)",
            amount: 21000,
          },
          {
            id: "ds25",
            uniqueCode: "DSTV_13314",
            name: "DSTV Compact Plus (1 Month)",
            amount: 14250,
          },
          {
            id: "ds26",
            uniqueCode: "DSTV_37514",
            name: "DSTV Compact (1 Month)",
            amount: 9000,
          },
          {
            id: "ds27",
            uniqueCode: "DSTV_12147",
            name: "DSTV Confam (1 Month)",
            amount: 7400,
          },
          {
            id: "ds28",
            uniqueCode: "DSTV_10393",
            name: "DSTV Premium + Xtraview (1 Month)",
            amount: 23900,
          },
          {
            id: "ds29",
            uniqueCode: "DSTV_58767",
            name: "DSTV PremiumAsia (1 Month)",
            amount: 23500,
          },
          {
            id: "ds30",
            uniqueCode: "DSTV_46982",
            name: "DSTV PremiumAsia + Xtraview (1 Month)",
            amount: 26400,
          },
          {
            id: "ds31",
            uniqueCode: "DSTV_67062",
            name: "DSTV Premium + French (1 Month)",
            amount: 29300,
          },
          {
            id: "ds32",
            uniqueCode: "DSTV_21415",
            name: "DSTV CompactPlus + French Plus (1 Month)",
            amount: 23550,
          },
          {
            id: "ds33",
            uniqueCode: "DSTV_52588",
            name: "DSTV Premium + French + Xtraview (1 Month)",
            amount: 32200,
          },
          {
            id: "ds34",
            uniqueCode: "DSTV_72601",
            name: "DSTV CompactPlus + Asia (1 Month)",
            amount: 21350,
          },
          {
            id: "ds35",
            uniqueCode: "DSTV_62222",
            name: "DSTV CompactPlus + French Touch (1 Month)",
            amount: 16900,
          },
          {
            id: "ds36",
            uniqueCode: "DSTV_24390",
            name: "DSTV CompactPlus + Asia + Xtraview (1 Month)",
            amount: 24250,
          },
        ];
      default:
        return [];
    }
  };

  const generatePaymentHash = (privateKey, payload) => {
    const message = JSON.stringify(payload);
    return hmacSHA512(message, privateKey).toString();
  };
  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-blue-500">
      <div className="flex flex-row items-center justify-center gap-10 sm:ml-[30rem] ml-[5rem]  mb-6">
        <Link to="/dashboard" className="text-white">
          <FaArrowLeft />
        </Link>
        <div>
          <img src={logo} alt="Logo" className="w-[40%] sm:w-[20%]" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-md flex flex-col items-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">TV Subscription</h2>
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
        {!validationData && (
          <form onSubmit={validateAccount} className="w-full max-w-md">
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">
                Choose a Provider:
              </label>
              <select
                className="w-full p-2 border rounded"
                value={selectedProduct}
                onChange={handleProductChange}
              >
                <option value="">Choose a Provider</option>
                <option value="Startimes">Startimes</option>
                <option value="GOTV">GOTV</option>
                <option value="Dstv">DSTV</option>
              </select>
            </div>

            {selectedProduct && (
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">
                  Choose a Package:
                </label>
                <select
                  className="w-full p-2 border rounded"
                  value={selectedPlan}
                  onChange={handlePlanChange}
                >
                  <option value="">Select Plan</option>
                  {getPlansByProduct(selectedProduct).map((plan) => (
                    <option
                      key={plan.id}
                      value={`${plan.name}|${plan.amount}|${plan.uniqueCode} `}
                    >
                      {plan.name} - ₦{plan.amount}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* {selectedPlan && (
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">
                Number of Months:
              </label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={noOfMonth}
                onChange={(e) => setNoOfMonth(parseInt(e.target.value))}
                min="1"
                max="12"
              />
            </div>
          )} */}

            {selectedPlan && (
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">
                  Smart Card No:
                </label>
                <input
                  type="text"
                  id="accountNumber"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
            )}
            {selectedPlan && (
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Amount:</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  value={amount}
                  readOnly
                />
              </div>
            )}

            <div className="flex items-center justify-between">
            <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                  </div>

                  
                ) : (
                  "Validate"
                )}
              </button>
            </div>
          </form>
        )}

        {validationData && (
          <form onSubmit={handleSubmit} className="w-full max-w-md">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="amount"
              >
                Customer Name
              </label>
              <input
                type="text"
                readOnly
                placeholder={validationData.name}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="amount"
              >
                Account Plan
              </label>
              <input
                type="text"
                readOnly
                placeholder={validationData.accountType}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {selectedPlan && (
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Amount:</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  value={amount}
                  readOnly
                />
              </div>
            )}
            <div className="flex items-center justify-between">
              <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Pay Now
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Subscription;
