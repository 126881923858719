import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// import hmacSHA512 from "crypto-js/hmac-sha512";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import logo from "../../../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowLeft } from "react-icons/fa";
const Data = () => {
  useEffect(() => {
    AOS.init();
  });
  const [amount, setAmount] = useState("");
  const [network, setNetwork] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const scriptRef = useRef(null);
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const publicKey = "af5c305eb9cf584311818eab71739f0866389195";
  const genId = `SW_${Math.floor(Math.random() * 1000000) + 1}`;
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://swifttek.onrender.com/userinfo", {
          method: "GET",
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.user);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
       // console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [authHeader]);

  // Load Monnify script
  useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://sdk.monnify.com/plugin/monnify.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://sdk.monnify.com/plugin/monnify.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
      scriptRef.current = script;
    } else {
      setScriptLoaded(true);
    }

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!scriptLoaded) {
      setError("Payment gateway is not loaded yet.");
      return;
    }

    const form = event.target;
    
    if (window.MonnifySDK) {
      window.MonnifySDK.initialize({
        amount: parseInt(form.amount.value, 10),
        currency: "NGN",
        reference: genId,
        customerFullName: `${userDetails.firstName} ${userDetails.lastName}`,
        customerEmail: userDetails.email,
        apiKey: "MK_TEST_XGML1DL413",
        contractCode: "5297787378",
        paymentDescription: "Swift Test",
        onComplete: (response) => {
          //console.log("Payment complete:", response);
          const transactionReference = response.transactionReference;
          if (response.status === "SUCCESS") {
            const payload = {
              plan: genId,
              Ported_number: true,
              network: network,
                mobile_number: phone,
                amount: parseInt(amount),
              
            };

            const accountNumber = phone;
           
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Token ${publicKey}`,

        
            };

            console.log("Sending request to API...");
            axios
              .post(
                "https://billerstest.xpresspayments.com:9603/api/v1/airtime/fulfil",
                payload,
                { headers }
              )
              .then((response) => {
                const data = response.data;
                //console.log('Payment Response:', data);
                const product = network;

                if (data.responseCode === "00") {
                  navigate(
                    "/dashboard/receipt",
                    {
                      state: {
                        referenceId: data.referenceId,
                        amount,
                        product,
                        accountNumber,
                        name: `${userDetails.firstName} ${userDetails.lastName}`,
                        transactionReference,
                      },
                    },
                   
                  );
                } else {
                  setError(data.responseMessage);
                  //console.log("Payment failed:", data.responseMessage);
                }
              })
              .catch((error) => {
                //console.error("Error making payment:", error);
                setError(
                  "An error occurred while processing your payment. Please try again."
                );
              });
          } else {
            setError("Payment was not successful. Please try again.");
            //console.error("Payment was not successful:", response);
          }
        },
        onClose: () => {
          console.log("Payment widget closed");
        },
      });
    } else {
      setError("Payment gateway is not loaded.");
      //console.error("MonnifySDK is not loaded");
    }
  };
  return <div>Data</div>;
};

export default Data;
