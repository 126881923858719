import React, { useState, useEffect, useRef } from "react";
import "./register.css";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { FiCheck, FiInfo } from "react-icons/fi";
import Otp from "./Otp";
import AOS from 'aos'
import 'aos/dist/aos.css'
const Register = () => {
  useEffect(() => {
    AOS.init()
  })
  const emailRef = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    emailRef.current.focus();
  }, []);
  useEffect(() => {
    setErrorMessage("");
  }, [email]);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setIsLoading(true);
    const form = event.target; // Get the form element
    const userData = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      phone: form.phone.value,
      email: form.email.value,
      password: form.password.value,
    };
    try {
      // Make the POST request to the API endpoint
      const response = await axios.post(
        "https://swifttek.onrender.com/signup",
        userData
      );

      // Clear the form inputs
      form.reset();

      // Update form submission status and display a success message
      if (response === 200) {
      }
      setFormSubmitted(true);
      setSuccess(true);
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // User already registered error
       
        setErrorMessage(
          "Please use a different email or Field is empty."
        );
      } else {
        // Generic server issue error
        
        setErrorMessage("An error occurred. Please try again.");
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      {success ? (
        <>
          <Otp email={email} />
        </>
      ) : (
        <div  className="flex flex-col  bg-[#084DD6]  items-center">
          <img src={logo} alt="" className="w-[40%] sm:w-[20%]" />

          <div data-aos="zoom-in" data-aos-duration="1500" className="w-[90%] sm:w-[40%] mx-auto rounded-md bg-white items-center justify-center flex flex-col">
            <h2 className="bai-jamjuree-bold text-[24px] sm:text-[28px] text-[#084DD6]">
              Let’s get to know you
            </h2>
            <p className="bai-jamjuree-light text-[#084DD6]">
              Register with Swifttek to gain access
            </p>
            {formSubmitted && (
              <span className="text-green-500 py-2 px-20 flex flex-row items-center justify-center text-[12px]">
                {" "}
                <FiCheck className="text-xl" /> Form submitted successfully!
              </span>
            )}
            {errorMessage && (
              <span className="text-red-500 py-2 px-20 flex flex-row items-center justify-center text-[10px] ">
                <FiInfo className="text-xl" />
                {errorMessage}
              </span>
            )}
            <form onSubmit={handleSubmit} className="w-[80%] pt-6 pb-8 mb-4">
              
                <div className="mb-4 ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
            

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  ref={emailRef}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstname"
                >
                  Phone
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="Phone Number"
                />
              </div>
              <div className="mb-6 relative">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute  right-0 px-4 py-3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaRegEyeSlash className="h-5 w-5 text-gray-500" /> // EyeOff icon when password is visible
                  ) : (
                    <FaRegEye className="h-5 w-5 text-gray-500" /> // Eye icon when password is hidden
                  )}
                </button>
              </div>
              <div className="flex items-center justify-between">
              <button
                className="bg-[#084DD6] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="text-white flex items-center justify-center flex-row">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121 2.121A7.965 7.965 0 0112 20v4c-2.27 0-4.368-.785-6-2.109L6 17.291z"
                    ></path>
                  </svg>
                  <span>Loading</span>
                  </div>

                  
                ) : (
                  "Register"
                )}
              </button>
                <Link to="/login" className="text-[#084DD6] bai-jamjuree-light">
                  Click Here to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
