import React, { useState, useEffect } from "react";
import axios from "axios";

const Payment = () => {
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('https://ofavourdatasub.com/api/user/', {
          headers: {
            'Authorization': 'Token af5c305eb9cf584311818eab71739f0866389195',
            'Content-Type': 'application/json'
          }
        });
        setFullName(response.data.user.FullName);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md text-center">
        <h2 className="text-2xl font-bold mb-4">User Information</h2>
        {fullName ? (
          <p className="text-lg">{fullName}</p>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Payment;
